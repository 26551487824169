<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="text-h5"><strong>TISSUE DISPOSITION</strong></span>
    </v-card-title>
   
   
      <!-- <v-card-title class="justify-start">
      <span class="font-weight-semibold">Tissue Disposition</span>
    </v-card-title> -->
     <v-card-title class="justify-center">
      <span class="font-weight-semibold">Summary</span>
    </v-card-title>
     <v-row class="ml-3 mr-5 pt-3 mb-3">
         <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue #</p>
      </v-col> 
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Intent</p>
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Outcome</p>
      </v-col>
       <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Sub OutCome</p>
      </v-col>
       <!-- <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0"> Tissue Outcome</p>
      </v-col> -->
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Reason(s) Not Released or Transplant</p>
      </v-col>
     </v-row>
     <v-row class="ml-3 mr-5 pt-3 mb-3" v-if="btn">
       <v-col cols="12" md="2">
        <v-text-field  v-model="TissueData.tissue_os_id" hide-details readonly></v-text-field>
      </v-col> 
       <v-col cols="12" md="2">
        <v-text-field   v-model="TissueData.os_intent" hide-details readonly></v-text-field>
      </v-col> 
       <v-col cols="12" md="2">
        <v-text-field  v-model="TissueData.os_outcome" hide-details  :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
       <v-col cols="12" md="3">
        <v-text-field  v-model="TissueData.os_sub_outcome" hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
       <v-col cols="12" md="3">
        <v-text-field  v-model="TissueData.os_reason_not_released_or_transplanted" hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
     </v-row>
     <v-row class="ml-3 mr-5 pt-3 mb-3" v-if="btnqq">
       <v-col cols="12" md="2">
        <v-text-field  v-model="TissueData.tissue_od_id" hide-details readonly></v-text-field>
      </v-col> 
      <v-col cols="12" md="2">
        <v-text-field  v-model="TissueData.od_intent" hide-details readonly></v-text-field>
      </v-col> 
      <v-col cols="12" md="2">
        <v-text-field  v-model="TissueData.od_outcome" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
      <v-col cols="12" md="3">
        <v-text-field  v-model="TissueData.od_sub_outcome" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
      
      <v-col cols="12" md="3">
        <v-text-field v-model="TissueData.od_reason_not_released_or_transplanted" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col> 
     
     </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mb-5" v-if="saveBtn" @click="addTissue()"> Save </v-btn>
    </v-card-actions>
     <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import TissueService from "@/service/Tissue.service"
import axios from "axios";
const baseUrl = "https://api.ebsr.in/";
const localData = JSON.parse(localStorage.getItem("token"));
const token = localData.token;

export default {

  data() {
    return {
      readOnlyField1:false,
      saveBtn:true,
    TissueData:{
      tissue_od_id:'',
      tissue_os_id: '',
      od_intent:'',
      os_intent:'',
      od_outcome:'',
      os_outcome: '',
      od_sub_outcome:'',
      os_sub_outcome:'',
      od_reason_not_released_or_transplanted:'',
      os_reason_not_released_or_transplanted:'',
      // od_tissue_outcome:'',
      // os_tissue_outcome:''
    },
    
    btnqq:'',
    btn:'',
        uploadKonasKssImg:null,
        image:null,
        listLoading: false,
        timeout: 2000,
        snackbar: false,
        snackbarText: '',
        // epithelium_clear_and_intact:'No'
        

        
    }
  },
   watch: {
    options: {
      handler() {
        this.Tissue_Detail()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.Tissue_Detail()
    this.getdonorList()
  },
  methods:{

     async Tissue_Detail() {
      const DonorId = this.$route.params.donor_id
      const service = new TissueService()
      let response = await service.TissueDisposition(DonorId)
      this.TissueData = response
      if(this.TissueData.tissue_os_id){
        this.btn = true
      }else{ 
        this.btn = false
        }
        if(this.TissueData.tissue_od_id){
        this.btnqq = true
      }else{
        this.btnqq = false
      }
    },

     async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[13]
        console.log("heloo",formData)
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },


    async addTissue() {
      if(this.TissueData.od_outcome == "" && this.TissueData.os_outcome == ""){
          return false,
        this.snackbarText = "Please Fill Outcome",
        this.snackbar  =true
      }
      else if(this.TissueData.od_reason_not_released_or_transplanted == "" && this.TissueData.os_reason_not_released_or_transplanted == ""){
        return false,
        this.snackbarText = "Please Fill Reason(s) Not Released or Transplant",
        this.snackbar  =true
      }
      const donorid = this.$route.params.donor_id;
      const data = {
        donor_id: donorid,
        od_outcome:this.TissueData.od_outcome,
        os_outcome:this.TissueData.os_outcome,
        od_sub_outcome:this.TissueData.od_sub_outcome,
        os_sub_outcome:this.TissueData.os_sub_outcome,
        od_reason_not_released_or_transplanted:this.TissueData.od_reason_not_released_or_transplanted,
        os_reason_not_released_or_transplanted:this.TissueData.os_reason_not_released_or_transplanted,
        // od_tissue_outcome:this.TissueData.od_tissue_outcome,
        // os_tissue_outcome:this.TissueData.os_tissue_outcome
      };
      try {
        const response = await axios.post(
          `${baseUrl}TissueDisposition/add`,
          data,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status == 200) {
        this.Tissue_Detail()
        this.snackbarText = response.data.msg;
         
        } else {
          this.snackbarText = response.data.msg;
        }
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

